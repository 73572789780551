import * as React from 'react'
import classNames from 'classnames'

import { AccountManagementHeaderLinks } from '../AccountManagementHeaderLinks'
import { BellIconWrapper } from '../BellIconWrapper'
import { HeaderDropdown } from '../HeaderDropdown'
import { HeaderLink } from '../HeaderLink'
import { MenuConfig } from 'types'

import styles from './HeaderMenu.module.scss'

export type Props = {
  active: boolean
  alertCount: number
  currentItem: string
  menuItems: MenuConfig[]
}

export const HeaderMenu = ({ active, alertCount, currentItem, menuItems }: Props): JSX.Element => {
  const [userMenuHovered, setUserMenuHovered] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setUserMenuHovered(false)
    }, 3000)
  }, [userMenuHovered])

  const menu = React.useMemo(() => menuItems.filter(menuItem => menuItem.path !== currentItem), [
    currentItem,
    menuItems,
  ])

  const userMenuItems = menu.map(({ path, menu, description, icon }) => {
    if (menu && icon !== 'user_account')
      return <HeaderDropdown key={path} label={description} menu={menu} />
    if (menu && icon === 'user_account')
      return <AccountManagementHeaderLinks key={path} menu={menu} />
    return <HeaderLink key={path} to={path} label={description} />
  })

  return (
    <ul className={classNames(styles.HeaderMenu, { [styles.HeaderMenuActive]: active })}>
      {userMenuItems}
      {alertCount > 0 && <BellIconWrapper />}
    </ul>
  )
}
