import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult, Alert } from 'types'

type AlertResponse = {
  alert_id: string
  date_added: string
  message: string
  title: string
  type_id: number
}

type GetAlertsParams = {
  token?: string
}
type GetAlerts = (params?: GetAlertsParams) => Promise<Alert[]>
export const getAlerts: GetAlerts = async (params = {}) => {
  const { token } = params

  const {
    data: { alert: alerts },
  } = await api.get<{ alert: AlertResponse[] }>(ENDPOINTS.alert, {
    params: {
      action: 'list',
      token,
    },
  })

  return alerts.map((alert: AlertResponse) => ({
    alertId: alert.alert_id,
    date: alert.date_added,
    message: alert.message,
    title: alert.title,
    typeId: alert.type_id,
  }))
}

export type AlertCountResponse = BaseApiResult & {
  count: number
  version: string
}
type AlertCountState = {
  count: number
  version: string
}
type GetAlertCount = () => Promise<AlertCountState>
export const getAlertCount: GetAlertCount = async () => {
  const params = {
    action: 'get_count',
  }
  const {
    data: { count, version },
  } = await api.get<AlertCountResponse>(ENDPOINTS.alert, { params })

  return { count, version }
}

type ProcessAlertResponse = {
  url: string
} & BaseApiResult
export const processAlert = async (alertId: string): Promise<string> => {
  const { data } = await api.post<ProcessAlertResponse>(ENDPOINTS.alert, {
    action: 'process',
    alert_id: alertId,
  })

  return data.url
}
