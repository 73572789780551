import axios, { AxiosError } from 'axios'
import { PaymentType } from 'types'

export const REQUIRED_FIELD_ERROR_MESSAGE = 'This field is required.'

type TClientError = {
  message: string
}

export type TApiError = AxiosError<{
  error?: string | null
}>

type TNormalizedError = {
  message: string
}

// Idea to handle errors is borrowed from here:
// https://stackoverflow.com/questions/68240884/error-object-inside-catch-is-of-type-unknown
export function normalizeError(error: unknown): TNormalizedError {
  const result: TNormalizedError = { message: '' }

  if (!error) {
    result.message = ''
  } else if (axios.isAxiosError(error)) {
    result.message = (error as TApiError).response?.data?.error || ''
  } else if (error instanceof Error) {
    result.message = error.message
  } else if (typeof error === 'string') {
    result.message = error
  } else if (typeof (error as TClientError)?.message === 'string') {
    result.message = (error as TClientError).message
  } else {
    result.message = String(`[${typeof error}]: ${String(error)}`)
  }

  return result
}

export function isApiError(error: unknown): boolean {
  if (axios.isAxiosError(error)) {
    const err = error as TApiError
    return Object.prototype.hasOwnProperty.call(err.response?.data, 'error')
  }
  return false
}

export function isLTLOrFTL(paradigm_name: string | undefined): boolean {
  if (
    paradigm_name?.toLowerCase() === 'less-than-truckload' ||
    paradigm_name?.toLowerCase() === 'full-truckload'
  ) {
    return true
  } else {
    return false
  }
}

export const isKitsAndLabelsParadigm = (paradigm_name: string | undefined): boolean => {
  return paradigm_name?.toLowerCase() === 'kits & labels'
}

export const mapPaymentType = (apiPaymentName: string): PaymentType => {
  if (apiPaymentName === 'Credit Card') {
    return PaymentType.CreditCard
  } else if (apiPaymentName === 'Invoice') {
    return PaymentType.Invoice
  } else if (apiPaymentName === 'Terms') {
    return PaymentType.Terms
  }
  return PaymentType.None
}
