import { AxiosPromise } from 'axios'

import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

type ResetPasswordParams = {
  userId: string
}
type ResetPassword = (params: ResetPasswordParams) => AxiosPromise<BaseApiResult>
export const resetPassword: ResetPassword = ({ userId }) => {
  return api.post(ENDPOINTS.user, {
    action: 'reset_password',
    user_id: userId,
  })
}
