import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

export type RedirectInfo = {
  // Response will include either job_id or quote_id depending on redirect type
  details: {
    brand_name: string
    job_id?: string
    lease_number: string
    quote_id?: string
  }
}

type GetRedirectionResponse = RedirectInfo & BaseApiResult

export const getRedirection = async (uniqueId: string): Promise<RedirectInfo> => {
  const { data } = await api.get<GetRedirectionResponse>(ENDPOINTS.user, {
    params: {
      action: 'get_redirection',
      unique_id: uniqueId,
    },
  })

  return data
}
