export const UNAUTHORIZED_ROUTES = {
  createAccount: '/create_account',
  forgotPassword: '/email_password_reset',
  forgotPasswordSuccess: '/email_password_reset_success',
  impersonate: (id?: string): string => `/impersonate/${id ? encodeURIComponent(id) : ':token'}`,
  login: '/login',
  passwordExpired: '/password_expired',
  redirect: (id?: string): string => `/r/${id ? encodeURIComponent(id) : ':uniqueId'}`,
  resetPassword: '/forgot_password',
  resetPasswordSuccess: '/reset_password_success',
}
