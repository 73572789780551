import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'
import { FormValues } from 'forms/CreateAccountForm'

type AddAccountResponse = BaseApiResult & {
  token: string
}

export const addRaAccount = async (
  values: FormValues,
  jobId: string,
  quoteId: string,
): Promise<string> => {
  const { data } = await api.post<AddAccountResponse>(ENDPOINTS.account, {
    action: 'add_ra_account',
    contact: {
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone,
    },
    job_id: jobId,
    password: values.password,
    quote_id: quoteId,
  })

  return data.token
}
