import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'
import { camelCaseKeys } from 'utils/camelCaseKeys'

export type GetFilterTypesResponse = BaseApiResult & {
  type: {
    description: string
    type_id: number
  }[]
}

export type FilterType = {
  description: string
  typeId: number
}

type GetFilterTypes = () => Promise<FilterType[]>

export const getFilterTypes: GetFilterTypes = async () => {
  const {
    data: { type },
  } = await api.get<GetFilterTypesResponse>(ENDPOINTS.setting, {
    params: {
      action: 'get_grid_filter_types',
    },
  })

  return camelCaseKeys(type)
}
