import React, { FC, lazy as reactLazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'

import { SignOut } from 'components/SignOut'

import {
  orderPath,
  dashboardPath,
  destinationRoutingsPath,
  destinationsPath,
  dispatchSearchPath,
  myAccountPath,
  orderDetailsPath,
  quoteDetailsPath,
  reportQueuePath,
  signOutPath,
  viewsPath,
  userAdminPath,
} from './Routes'

import { ErrorBoundary } from './ErrorBoundary'
import { ExpiredPasswordForm } from '../ExpiredPasswordForm'
import { ForgotPasswordPage } from '../ForgotPasswordPage'
import { ForgotPasswordSuccessPage } from '../ForgotPasswordSuccessPage'
import { LogInPage } from '../LogInPage'
import { LogInModal } from '../LogInModal'
import { Page } from 'components/Page'
import { ProtectedRoute } from '../ProtectedRoute'
import { ResetPasswordPage } from '../ResetPasswordPage'
import { UNAUTHORIZED_ROUTES } from '../../Routes'
import { UnauthorizedPage } from '../UnauthorizedPage'
import { UnAuthorizedResetPWSuccess } from '../UnauthorizedResetPWSuccess'

const lazy: typeof reactLazy = fn => {
  return reactLazy(() =>
    fn().catch(error => {
      if (error.name === 'ChunkLoadError') window.location.reload()
      throw error
    }),
  )
}

const CreateAccountPage = lazy(() => import('pages/CreateAccountPage'))
const DashboardPage = lazy(() => import('pages/DashboardPage'))
const DestinationRoutingsPage = lazy(() => import('pages/DestinationRoutingsPage'))
const DestinationsPage = lazy(() => import('pages/DestinationsPage'))
const DispatchesPage = lazy(() => import('pages/DispatchesPage'))
const DispatchSearchResultsPage = lazy(() => import('pages/DispatchSearchResultsPage'))
const ImpersonatePage = lazy(() => import('pages/ImpersonatePage'))
const MyAccountPage = lazy(() => import('pages/MyAccountPage'))
const OrderDetailsPage = lazy(() => import('pages/OrderDetailsPage'))
const OrderPages = lazy(() => import('pages/OrderPages'))
const QuoteDetailsPage = lazy(() => import('pages/QuoteDetailsPage'))
const RedirectPage = lazy(() => import('pages/RedirectPage'))
const ReportQueuePage = lazy(() => import('pages/ReportQueuePage'))
const UserAdminPage = lazy(() => import('pages/UserAdminPage'))

const LoadingPage: FC = () => <Page />

export const Router = (): JSX.Element => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <ErrorBoundary>
      <Suspense fallback={<LoadingPage />}>
        <LogInModal />
        <Switch>
          <Route exact path={UNAUTHORIZED_ROUTES.createAccount}>
            <CreateAccountPage />
          </Route>
          <Route exact path={UNAUTHORIZED_ROUTES.login}>
            <LogInPage />
          </Route>

          <Route exact path={UNAUTHORIZED_ROUTES.passwordExpired}>
            <UnauthorizedPage>
              <ExpiredPasswordForm />
            </UnauthorizedPage>
          </Route>

          <Route exact path={UNAUTHORIZED_ROUTES.forgotPassword}>
            <UnauthorizedPage maxWidth={525}>
              <ForgotPasswordPage />
            </UnauthorizedPage>
          </Route>

          <Route exact path={UNAUTHORIZED_ROUTES.forgotPasswordSuccess}>
            <UnauthorizedPage>
              <ForgotPasswordSuccessPage />
            </UnauthorizedPage>
          </Route>

          <Route path={UNAUTHORIZED_ROUTES.impersonate()}>
            <UnauthorizedPage>
              <ImpersonatePage />
            </UnauthorizedPage>
          </Route>

          <Route exact path={`${UNAUTHORIZED_ROUTES.resetPassword}/:uid`}>
            <UnauthorizedPage>
              <ResetPasswordPage />
            </UnauthorizedPage>
          </Route>

          <Route exact path={UNAUTHORIZED_ROUTES.resetPasswordSuccess}>
            <UnauthorizedPage>
              <UnAuthorizedResetPWSuccess />
            </UnauthorizedPage>
          </Route>

          <Route path={UNAUTHORIZED_ROUTES.redirect()}>
            <RedirectPage />
          </Route>

          <ProtectedRoute path={dashboardPath} exact>
            <DashboardPage />
          </ProtectedRoute>
          <ProtectedRoute path={destinationRoutingsPath} exact>
            <DestinationRoutingsPage />
          </ProtectedRoute>
          <ProtectedRoute path={destinationsPath} exact>
            <DestinationsPage />
          </ProtectedRoute>
          <ProtectedRoute path={dispatchSearchPath} exact>
            <DispatchSearchResultsPage />
          </ProtectedRoute>
          <ProtectedRoute path={myAccountPath} exact>
            <MyAccountPage />
          </ProtectedRoute>
          <ProtectedRoute path={orderDetailsPath()} exact>
            <OrderDetailsPage />
          </ProtectedRoute>
          <ProtectedRoute path={orderPath}>
            <OrderPages />
          </ProtectedRoute>
          <ProtectedRoute path={quoteDetailsPath()} exact>
            <QuoteDetailsPage />
          </ProtectedRoute>
          <ProtectedRoute path={reportQueuePath} exact>
            <ReportQueuePage />
          </ProtectedRoute>
          <ProtectedRoute path={userAdminPath} exact>
            <UserAdminPage />
          </ProtectedRoute>
          <ProtectedRoute path={viewsPath} exact>
            <DispatchesPage />
          </ProtectedRoute>

          <ProtectedRoute path={signOutPath} exact>
            <SignOut />
          </ProtectedRoute>

          <Redirect from={'/'} to={viewsPath} />
          <Route>
            <Page>
              <h1>Not Found</h1>
            </Page>
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  </BrowserRouter>
)
