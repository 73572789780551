import { AxiosPromise } from 'axios'

import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

type DeleteUserParams = {
  userId: string
}
type DeleteUser = (params: DeleteUserParams) => AxiosPromise<BaseApiResult>
export const deleteUser: DeleteUser = ({ userId }) => {
  return api.delete(ENDPOINTS.user, {
    data: {
      action: 'delete',
      user_id: userId,
    },
  })
}
