import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { getDefaultPath } from '../../utils/getDefaultPath'
import { MenuConfig } from 'types'
import { useScrollPosition } from 'components/Header/hooks/useScrollPosition'

import { HeaderView } from './Header'
import { loginPath } from 'components/Router/Routes'
import { useAppContext } from 'hooks/useAppContext'

type Props = {
  additionalActions?: React.ReactNode
}

export const Header = ({ additionalActions }: Props): JSX.Element => {
  const { alertCount, user } = useAppContext()
  const { pathname } = useLocation()

  const defaultPath = getDefaultPath(user)
  const onLoginPage = pathname === loginPath
  const menuForCurrentPath = '/' + pathname.split('/')[1]
  const allMenuItems = ([] as MenuConfig[]).concat(
    ...(user?.menu.map(item => item.menu || item) ?? []),
  )

  const pageTitle = allMenuItems.find(({ path }) => path === menuForCurrentPath)?.description
  const scrollPosition = useScrollPosition()

  const [collapsedMenuOpen, setCollapsedMenuOpen] = React.useState(false)

  return (
    <HeaderView
      additionalActions={additionalActions}
      defaultPath={defaultPath}
      menuItems={user?.menu ?? []}
      currentMenuItem={menuForCurrentPath}
      mobileMenuOpen={collapsedMenuOpen}
      alertCount={alertCount}
      onLoginPage={onLoginPage}
      pageTitle={pageTitle}
      scrollPosition={scrollPosition}
      toggleMobileMenu={() => setCollapsedMenuOpen(!collapsedMenuOpen)}
    />
  )
}
