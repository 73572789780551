import {
  ApiCompany,
  BaseApiResult,
  ColorKey,
  CompanyInfo,
  CurrentUser,
  MenuConfig,
  OrderReferenceNumber,
  Paradigm,
  Program,
} from 'types'
import { filterMenuItems } from 'lib/api/user/utils/filterMenuItems'
import { camelCaseKeys } from 'utils/camelCaseKeys'
import { mapApiToUiCompany } from '../../getUser'

type GridResponse = {
  config_id: number
  current?: boolean
  name: string
}

export type LegacyAuthResponse = BaseApiResult & {
  legacy: 1
}

export type MenuConfigResponse = {
  color: string
  config_id?: number
  description: string
  grid?: GridResponse[]
  icon: string
  menu?: MenuConfigResponse[]
  menu_id: number
  path: string
}

export type UserAuthResponse = BaseApiResult & {
  bill_company: ApiCompany
  brand_id: number
  color_key?: ColorKey
  company: string
  culture_id: number
  default_destination_id: number
  default_menu_id: number
  default_path: string
  destination_routing: boolean
  email: string
  expired_message: string
  first_name: string
  last_name: string
  locale: string
  menu: MenuConfigResponse[]
  notification_interval: number
  paradigms: Paradigm[]
  parent_brand_id: number
  password_expired: boolean
  password_policy: string
  perspective_id: number
  programs: Program[]
  reference_numbers: OrderReferenceNumber[]
  show_price: boolean
  token: string
  w9_url: string
}

type CamelCasedUserAuthResponse = Partial<BaseApiResult> & {
  bill_company?: ApiCompany // this key will be removed
  billingInfo: CompanyInfo
  brandId: number
  colorKey?: ColorKey
  company: string
  cultureId: number
  defaultDestinationId: number
  defaultMenuId: number
  defaultPath: string
  destinationRouting: boolean
  email: string
  expiredMessage: string
  firstName: string
  lastName: string
  locale: string
  menu: MenuConfig[]
  notificationInterval?: number
  paradigms: Paradigm[]
  parentBrandId: number
  passwordExpired: boolean
  passwordPolicy: string
  perspectiveId: number
  poiUrl: string
  programs: Program[]
  referenceNumbers: OrderReferenceNumber[]
  showPrice: boolean
  token: string
  w9Url: string
}

type FormatUserResponse = (data: UserAuthResponse) => CurrentUser
export const formatUserResponse: FormatUserResponse = data => {
  const rawCamelCased = camelCaseKeys<UserAuthResponse, CamelCasedUserAuthResponse>(data, {
    deep: true,
    exclude: ['bill_company'],
  })

  delete rawCamelCased.bill_company
  delete rawCamelCased.notificationInterval
  delete rawCamelCased.result

  return {
    ...rawCamelCased,
    billingInfo: mapApiToUiCompany(data.bill_company),
    loggedInAt: Date.now(),
    menu: filterMenuItems(data.menu),
    menuConfigIdCache: {},
    notificationIntervalMs: data.notification_interval * 1000,
    paradigms: data.paradigms,
    programs: data.programs,
    tokenExpired: false,
  }
}
