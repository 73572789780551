import { AxiosResponse } from 'axios'
import { E164Number } from 'libphonenumber-js'

import { api } from 'lib/api/base'
import { ApiCompany, BaseApiResult, Perspective } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

type BaseUpdateUserParams = {
  bill_company: ApiCompany
  cellPhone?: E164Number
  company: ApiCompany
  country: string
  cultureId: number
  defaultDestinationId: number
  email: string
  firstName: string
  lastName: string
  phone: E164Number
  sendNotificationEmail: boolean
  sendNotificationText: boolean
  userName: string
}

type UpdateCurrentUserParams = BaseUpdateUserParams & {
  menuId: number
}

type UpdateOtherUserParams = BaseUpdateUserParams & {
  brandId: number
  perspectiveId: Perspective
}

type TUpdateUserResult = { locale: string }
export type TUpdateUserResponse = BaseApiResult & TUpdateUserResult

export type TUpdateUserFunc = {
  (user: UpdateCurrentUserParams): Promise<TUpdateUserResult>
  (user: UpdateOtherUserParams, userId: string): Promise<TUpdateUserResult>
}

export const updateUserAccount: TUpdateUserFunc = async (
  user: UpdateOtherUserParams | UpdateCurrentUserParams,
  userId?: string,
) => {
  const baseRequestArgs = {
    action: 'update',
    bill_company: user.bill_company,
    cell_phone: user.cellPhone,
    company: user.company,
    country: user.country,
    culture_id: user.cultureId,
    destination_id: user.defaultDestinationId,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    phone: user.phone,
    send_notification_email: Number(user.sendNotificationEmail),
    send_notification_text: Number(user.sendNotificationText),
    user_name: user.userName,
  }

  let response: AxiosResponse<TUpdateUserResponse>
  if (userId === undefined) {
    response = await api.post<TUpdateUserResponse>(ENDPOINTS.account, {
      ...baseRequestArgs,
      menu_id: (user as UpdateCurrentUserParams).menuId,
    })
  } else {
    const updateOtherUserParams = user as UpdateOtherUserParams
    response = await api.post<TUpdateUserResponse>(ENDPOINTS.account, {
      ...baseRequestArgs,
      brand_id: updateOtherUserParams.brandId,
      perspective_id: updateOtherUserParams.perspectiveId,
      user_id: userId,
    })
  }

  return { locale: response.data.locale }
}
