import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult } from 'types'
import { camelCaseKeys } from 'utils/camelCaseKeys'

export enum FilterInputType {
  currency = 'currency',
  date = 'date',
  integer = 'integer',
  sla = 'sla',
  string = 'string',
}

export type GetGridColumnTypesResponse = BaseApiResult & {
  type: {
    description: string
    filter_types: number[]
    type_id: number
  }[]
}

export type NormalizedGridColumnType = {
  description: FilterInputType
  filterTypes: number[]
  typeId: number
}
type GetGridColumnTypes = () => Promise<NormalizedGridColumnType[]>
export const getGridColumnTypes: GetGridColumnTypes = async () => {
  const {
    data: { type },
  } = await api.get<GetGridColumnTypesResponse>(ENDPOINTS.setting, {
    params: { action: 'get_grid_column_types' },
  })

  return camelCaseKeys(type)
}
