import React, { ReactNode } from 'react'

import { DialogBox } from 'components/DialogBox'
import { useErrorMessageContext } from 'hooks/useErrorMessageContext'

import styles from './ErrorMessageDialogBox.module.scss'

export const getDefaultErrorText = (errorMessage: ReactNode): ReactNode => {
  const href =
    typeof errorMessage === 'string'
      ? `mailto:support@onepak.com?subject=OnePak Partner Community error: ${errorMessage}`
      : 'mailto:support@onepak.com?subject=OnePak Partner Community error'

  return (
    <p>
      Please <a href={href}>contact support</a> for assistance in correcting this problem.
    </p>
  )
}

export const ErrorMessageDialogBox = (): JSX.Element => {
  const { errorMessage, setErrorMessage } = useErrorMessageContext()

  return (
    <DialogBox
      hide={() => setErrorMessage(null)}
      show={Boolean(errorMessage)}
      dialogClassName={styles.ErrorDialog}
      buttonClassName={styles.ErrorDialogButton}
      buttonText="Close"
    >
      <div>
        <div>{errorMessage}</div>
        <br />
        <div>{getDefaultErrorText(errorMessage)}</div>
      </div>
    </DialogBox>
  )
}
