import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'

type UserSettings = {
  destination_routing: boolean
}

export const updateUserSettings = async (settings: UserSettings): Promise<void> =>
  api.post(ENDPOINTS.user, {
    action: 'update_settings',
    ...settings,
  })
