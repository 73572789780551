import React, { FC } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import './UserAccountIcon.scss'
import { MenuConfig } from 'types'
import styles from './UserAccountIcon.module.scss'
import { TransparentButton } from 'components/TransparentButton'

type Props = {
  menu: MenuConfig[]
}
export const UserAccountIcon: FC<Props> = ({ menu }) => {
  const dropdownItemClassName = classnames('dropdown-item', styles.DropdownItem)

  const menuItems = menu.map(({ path, description }) => {
    return (
      <Link key={path} to={path} className={dropdownItemClassName}>
        {description}
      </Link>
    )
  })

  return (
    <>
      <OverlayTrigger
        delay={{ hide: 1500, show: 100 }}
        overlay={
          <Tooltip id="tooltip-user-account-icon">
            <div className={styles.Tooltip}>{menuItems}</div>
          </Tooltip>
        }
        placement="bottom"
      >
        <div className={styles.UserIconWrapper}>
          <TransparentButton className={styles.UserIcon} onClick={() => null} />
        </div>
      </OverlayTrigger>
    </>
  )
}
