import * as React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import Row from 'react-bootstrap/Row'
import * as Yup from 'yup'

import { passwordValidator } from 'utils/passwordValidator'
import { TextField } from 'components/TextField'

import styles from './ChangePasswordForm.module.scss'
import { TFormSubmit } from 'types'

export type FormValues = {
  newPassword: string
  newPasswordConfirmation: string
  oldPassword: string
}

const BasePasswordValidation = Yup.object({
  newPasswordConfirmation: Yup.string()
    .required('Please confirm new password')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
  oldPassword: Yup.string().required('Please enter your old password'),
})

const ChangePasswordValidationDell = BasePasswordValidation.shape({
  newPassword: Yup.string()
    .required('Please enter a password')
    .test({
      exclusive: true,
      message: 'Password does not meet requirements',
      name: 'passwordTest',
      params: undefined,
      test: value => passwordValidator(value as string),
    }),
})

const ChangePasswordValidationOnePak = BasePasswordValidation.shape({
  newPassword: Yup.string()
    .required('Please enter a password')
    .min(6, 'Password does not meet requirements'),
})

export type Props = {
  formId?: string
  hideSubmit?: boolean
  initialValues: FormValues
  onSubmit: TFormSubmit<FormValues, void>
  passwordPolicy?: string
}

export const ChangePasswordForm = ({
  formId = 'change-password',
  hideSubmit = false,
  initialValues,
  onSubmit,
  passwordPolicy,
}: Props): JSX.Element => {
  const DellRequirements = () => {
    return (
      <ul className={styles.PasswordCriteria}>
        <li>Passwords expire after 90 days</li>
        <li>Passwords must be at least 8 characters long</li>
        <li>
          Passwords must include 3 out of the following 4 options:
          <ol>
            <li>Uppercase Alpha Character</li>
            <li>Lowercase Alpha Character</li>
            <li>Numeric Character</li>
            <li>&ldquo;Special&rdquo; Character(i.e., @,$,&amp;,*)</li>
          </ol>
        </li>
      </ul>
    )
  }

  const OnePakRequirements = () => {
    return (
      <ul className={styles.PasswordCriteria}>
        <li>Passwords must be at least 6 characters long</li>
      </ul>
    )
  }

  return (
    <div className={styles.Container}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={
          passwordPolicy === 'dell' ? ChangePasswordValidationDell : ChangePasswordValidationOnePak
        }
      >
        {({ handleSubmit }) => {
          return (
            <Form id={formId} className={styles.Form} onSubmit={handleSubmit}>
              <div className="mb-5">
                <p className={styles.PromptText}>
                  <strong>Your password is set up with the following rules:</strong>
                </p>
                {passwordPolicy === 'dell' ? <DellRequirements /> : <OnePakRequirements />}
              </div>

              <TextField
                className="mb-3"
                label="Current Password"
                name="oldPassword"
                type="password"
              />
              <TextField className="mb-3" label="New Password" name="newPassword" type="password" />
              <TextField
                className="mb-3"
                label="Re-enter Password"
                name="newPasswordConfirmation"
                type="password"
              />

              {!hideSubmit && (
                <Row className={styles.RightAlignedRow}>
                  <Button className={styles.Reset} type="submit">
                    Change Password
                  </Button>
                </Row>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
