import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'custom-event-polyfill'

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import React from 'react'
import ReactDOM from 'react-dom'

import './index.scss'
import { App } from 'components/App'
import { getIeVersion } from 'utils/getIeVersion'
import { renderBrowserFail } from './renderBrowserFail'
import * as serviceWorker from './serviceWorker'

const ieVersion = getIeVersion()
if (ieVersion && ieVersion < 11) {
  const el = document.getElementById('root')
  el !== null && (el.innerHTML = renderBrowserFail(ieVersion))
} else if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_USE_MOCK_API === '1') {
  void import('lib/api/axiosMock').then(() => {
    ReactDOM.render(<App />, document.getElementById('root'))
  })
} else {
  ReactDOM.render(<App />, document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// Unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
