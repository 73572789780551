export const createAccount = '/create_account'
export const dashboardPath = '/dashboard'
export const destinationRoutingsPath = '/destination_routings'
export const destinationsPath = '/destinations'
export const dispatchSearchPath = '/dispatch_search'
export const editOrderPath = (id?: string): string =>
  `${orderPath}/${id ? encodeURIComponent(id) : ':orderId(\\d+)'}`
export const emailPasswordResetPath = '/email_password_reset'
export const loginPath = '/login'
export const myAccountPath = '/account'
export const orderDetailsPath = (id?: string): string =>
  `/order_details/${id ? encodeURIComponent(id) : ':jobId'}`
export const orderPath = '/order'
export const quoteDetailsPath = (id?: string): string =>
  `/quote_details/${id ? encodeURIComponent(id) : ':quoteId'}`
export const reportQueuePath = '/report_queue'
export const resetPasswordSuccessPath = '/reset_password_success'
export const rootPath = '/'
export const signOutPath = '/sign_out'
export const userAdminPath = '/user_admin'
export const toolsPath = '/tools'
export const viewsPath = '/views'
