/* eslint-disable @typescript-eslint/no-explicit-any */
// see https://github.com/axios/axios/blob/master/lib/utils.js

const toString = (val: unknown) => Object.prototype.toString.call(val)

export const isArrayBuffer = (val: unknown): val is ArrayBuffer => {
  return toString(val) === '[object ArrayBuffer]'
}

export const isArrayBufferView = (val: unknown): val is ArrayBufferView => {
  let result = false
  if (typeof ArrayBuffer !== 'undefined' && ArrayBuffer.isView) {
    result = ArrayBuffer.isView(val)
  } else if (val) {
    result =
      (val as ArrayBufferView).buffer && (val as ArrayBufferView).buffer instanceof ArrayBuffer
  }
  return result
}

export const isBlob = (val: unknown): val is Blob => {
  return toString(val) === '[object Blob]'
}

export const isFile = (val: unknown): val is File => {
  return toString(val) === '[object File]'
}

export const isFormData = (val: unknown): val is FormData => {
  return typeof FormData !== 'undefined' && val instanceof FormData
}

export const isURLSearchParams = (val: unknown): val is URLSearchParams => {
  return typeof URLSearchParams !== 'undefined' && val instanceof URLSearchParams
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isAddressVerificationError = (error: any): boolean => {
  return error.response.data.address !== undefined
}
