import React, { useLayoutEffect, useRef } from 'react'
import { Action, SuspendedActions } from '../App/suspendedActions'
import { api } from '../../lib/api/base'
import { AppContext } from 'state/context'
import { attachToken } from '../App/attachToken'
import { CurrentUser, SetUser } from 'types'
import { isAddressVerificationError } from '../App/utils'
import { Loader } from '../Loader'
import { useErrorMessageContext } from '../../hooks/useErrorMessageContext'
import axios from 'axios'
import { TApiError } from 'utils/commonUtils'

export type PollingState = {
  alertCount: number
  version: string
}

type ContextProviderProps = {
  alertCount: number
  children: React.ReactNode
  initializing: boolean
  refreshAlertCount: () => void
  setUser: SetUser
  user?: CurrentUser
}

export const activeSessionCookie = 'onepakWeb/activeSession'

export const ContextProvider = ({
  alertCount = 0,
  children,
  initializing,
  refreshAlertCount,
  setUser,
  user,
}: ContextProviderProps): JSX.Element => {
  const { setErrorMessage } = useErrorMessageContext()

  const suspendedRetriesRef = useRef(new SuspendedActions())

  useLayoutEffect(() => {
    if (user && !user.tokenExpired) {
      const { token } = user
      const reqInterceptorId = api.interceptors.request.use(config => {
        return attachToken(config, token)
      })
      const resInterceptorId = api.interceptors.response.use(
        response => response,
        error => {
          if (error.response?.status === 401) {
            setUser({ ...user, tokenExpired: true })
            return new Promise(resolve => {
              suspendedRetriesRef.current.add(resolve as Action)
            }).then(() => {
              return api.request((error as TApiError).config)
            })
          } else if (axios.isAxiosError(error) && Number(error.response?.status) < 500) {
            if (!isAddressVerificationError(error)) {
              setErrorMessage((error as TApiError).response?.data?.error || '')
            }
          } else {
            const errorData = {
              data: error.config.data || error.config.params,
              endpoint: error.config.url,
              message: error.message,
              pathname: window.location.pathname,
              stackTrace: error.stack,
              userAgent: navigator.userAgent,
            }

            setErrorMessage('There was a problem fulfilling your request.', {
              identifier1: JSON.stringify(errorData),
              identifierType1: 'API error',
            })
          }

          return Promise.reject(error)
        },
      )
      return () => {
        api.interceptors.request.eject(reqInterceptorId)
        api.interceptors.response.eject(resInterceptorId)
      }
    }
  }, [setErrorMessage, setUser, user])

  return (
    <AppContext.Provider
      value={{
        alertCount,
        initializing,
        refreshAlertCount,
        setUser,
        suspendedRetriesRef,
        user,
      }}
    >
      {initializing ? (
        <div className="d-flex h-100">
          <Loader />
        </div>
      ) : (
        children
      )}
    </AppContext.Provider>
  )
}
