import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'
import { TUserFormValues } from 'components/AdminUserModal'

export type TAddBaseUserFunc = (params: TUserFormValues) => Promise<BaseApiResult>
export const addUser: TAddBaseUserFunc = ({
  cellPhone,
  country,
  cultureId,
  email,
  firstName,
  lastName,
  perspectiveId,
  phone,
  sendNotificationEmail,
  sendNotificationText,
  weightUom,
}) => {
  return api.post(ENDPOINTS.user, {
    action: 'add',
    cell_phone: cellPhone,
    country,
    culture_id: cultureId,
    email,
    first_name: firstName,
    last_name: lastName,
    perspective_id: perspectiveId,
    phone,
    send_notification_email: sendNotificationEmail ? 1 : 0,
    send_notification_text: sendNotificationText ? 1 : 0,
    weight_uom: weightUom,
  })
}
