import React, { FC } from 'react'

import { MenuConfig } from 'types'
import { UserAccountIcon } from '../UserAccountIcon'

export type Props = {
  menu: MenuConfig[]
}

export const AccountManagementHeaderLinks: FC<Props> = ({ menu }) => {
  return <UserAccountIcon menu={menu} />
}
